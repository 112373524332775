// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import formatLongNumber from '../../utils/formatLongNumber'
import axios from 'axios'
import { Pool } from '../../api/swap'

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.45);
  border: 1px solid #a7bfa7;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);
  border-radius: 15px;
  padding: 21px 30px ;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  .symbol {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    .boxImg {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        border-radius: 50%;
        overflow: hidden;
        &:last-child {
          position: relative;
          z-index: 1;
          left: -5px;
        }
        &:first-child {
          position: relative;
          /* left: 5px; */
        }
      }
      .placeholder {
        opacity: 0.5;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        &:last-child {
          position: relative;
          z-index: 1;
        }
        &:first-child {
          position: relative;
          left: 5px;
        }
      }
    }
    .name {
      font-weight: 700;
      font-size: 20px;
      color: #181818;
    }
  }
  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      &:first-child {
        opacity: 0.6;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      &:last-child {
        margin-top: 10px;
        font-weight: 500;
        font-size: 18px;
        color: #181818;
      }
    }
  }
`

export default function PoolsMobile({ address }) {
  const history = useHistory()

  function jump(token) {
    history.push('/explore/pools/' + token)
  }
  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Pool({ wd: address })
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
    getJson()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let [jsonData, setJsonData] = useState([])
  async function getJson() {
    let data = await axios.get('https://tokchain.org/static/tok1.json')
    setJsonData(data.data.tokens)
  }
  function getLogoURIByAddress(address) {
    const lowerCaseAddress = address.toLowerCase()
    const foundItem = jsonData?.find(item => item.address.toLowerCase() === lowerCaseAddress)
    return foundItem ? foundItem.logoURI : null
  }

  return (
    <div className="list" style={{ marginTop: '16px' }}>
      {list.map((item, index) => {
        return (
          <Item key={index} onClick={() => jump(item.pair)}>
            <div className="symbol">
              <div className="boxImg">
                {getLogoURIByAddress(item.token0) && getLogoURIByAddress(item.token1) ? (
                  <>
                    <img src={getLogoURIByAddress(item.token0)} alt="" />
                    <img src={getLogoURIByAddress(item.token1)} alt="" />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <span className="name">
                {item.symbol0}/{item.symbol1}
              </span>
            </div>
            <div className="item">
              <span>TVL</span>
              <span>${item.tvl}</span>
            </div>
            <div className="item">
              <span>1D vol</span>
              <span>${item.dayVol}</span>
            </div>
            <div className="item">
              <span>30D vol</span>
              <span>${item.day30Vol}</span>
            </div>
            <div className="item">
              <span>1D vol /TVL</span>
              <span>{item.volTvlRatio24h}</span>
            </div>

            {/* <div className="item-left">
              <div className="left-box">
                <div className="price">
                  ${item.tvl}
                  <span>TVL</span>
                </div>
                <div className="box">
                  <div className="data">
                    <div>1D vol:</div>
                    <span>${item.dayVol}</span>
                    <div>30D vol :</div>
                    <span>${item.day30Vol}</span>
                  </div>
                  <div className="volume">
                    <div>1D vol /TVL:</div>
                    <span>{item.volTvlRatio24h}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-right">
              <div className="token">
                {getLogoURIByAddress(item.token0) && getLogoURIByAddress(item.token1) ? (
                  <div className="boxImg">
                    <img src={getLogoURIByAddress(item.token0)} alt="" />
                    <img src={getLogoURIByAddress(item.token1)} alt="" />
                  </div>
                ) : (
                  <div className="boxImg">
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                  </div>
                )}
              </div>
              <div className="token-name">
                {item.symbol0}/{item.symbol1}
              </div>
            </div> */}
          </Item>
        )
      })}
    </div>
  )
}
