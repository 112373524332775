import React from 'react'
import swapBodyBg from '../../assets/images/swapBodyBg.png'
import swapContentbox1 from '../../assets/images/swapContentbox1.png'
import swapContentbox3 from '../../assets/images/swapContentbox3.png'
import brainChair from '../../assets/images/brain-chair.gif'
import swapBg from '../../assets/images/swapBg.jpg'
import mobileSwapBodyBg from '../../assets/images/mobile-swapBodyBg.png'
import Lottie from 'react-lottie'
import arrowsJson from '../../assets/animation/arrows.json'
import bubbleJson from '../../assets/animation/bubble.json'
import sideicon from '../../assets/images/sideicon.png'

import { isMobile } from 'react-device-detect'

import styled from 'styled-components'
const HomeWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 170px;
  min-height: calc(100vh );
  /* background: url('${swapBg}')top center/auto; */
  @keyframes move {
    0% {
      box-shadow: 0 0 #00ff0d;
    }
    100% {
      box-shadow: 0 0 44px 6px #00ff0d;
    }
  }
  .content{
    /* margin-top: -100px;
    overflow */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .swapBox {
      width: 450px;
      /* min-height: 645px; */
      /* padding: 71px 83px 73px 41px;
      background: url('${swapBodyBg}')top center/100% 100%; */
      padding: 43px;
      background: #335db2;
      border-radius: 20px;
      border: 4px solid #000;
      position: relative;
      margin-right: 50px;
      flex-shrink: 0;
      &::after{
        position: absolute;
        content: '';
        width: 52px;
        height: 137px;
        right: -51px;
        top: 35%;
        background: url('${sideicon}')top center/100% 100%;  ;
      }
        .decoratebox{
          position: relative;
          left: -30px;
          top: -30px;
          display: flex;
          align-items: center;
          gap: 10px;
          .decorate1{
            width: 15px;
            height: 15px;
            background: #5EB049;
            border-radius: 50%;
          }
          .decorate2{
            width: 46px;
            height: 13px;
            background: #F44F4F;
            border-radius: 7px 7px 7px 7px;
          }
          .decorate3{
            width: 15px;
            height: 15px;
            background: #E29593;
            border-radius: 50%;
          }
        }
        .sideRight{

        }
      .box {
        height: 100%;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        padding: 18px 18px 33px 18px;
        font-family: 'Inter'
      }
    }
    .flex{
      display: flex;
      align-items: center;
    }
    .center {
      margin-left: 90px;
      .center-title {
        white-space: nowrap;
        font-weight: 900;
        font-size: 48px;
        color: #000000;
        font-style: italic;
      }
      .big-title{
        white-space: nowrap;
        font-size: 60px;
        color: #5EB049;
        line-height: 70px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 4px #000000;
      }
      .contentBox1 {
        margin-top: 20px;
        width: 513px;
        height: 210px;
        padding: 27px 99px 27px 25px;
        background: url('${swapContentbox1}')top center/100% 100%;
        .item {
          margin-bottom: 16px;
          span {
            font-weight: 900;
            font-size: 16px;
            color: #ffffff;
            line-height: 20px;
          }
          .item-title {
            display: inline-block;
            font-size: 18px;
            color: #5eb049;
            line-height: 16px;
            text-transform: none;
            -webkit-text-stroke: 1px #000000;
            margin-right: 1em;
          }
        }
      }
      .contentBox2 {
        margin-top: 26px;
        max-width: 425px;
        padding: 29px 28px;
        background: #a6d59d;
        border-radius: 30px 30px 30px 30px;
        border: 3px solid #83a97b;
        font-weight: 900;
        font-size: 16px;
        color: #000000;
        line-height: 20px;
        margin-left: 40px;
      }
      .contentBox3 {
        margin-top: 26px;
        max-width: 459px;
        height: 170px;
        padding: 29px 20px;
        background: url('${swapContentbox3}')top center/100% 100%;
        font-weight: 900;
        font-size: 16px;
        color: #000000;
        line-height: 20px;
        margin-left: 70px;
        .content-title {
          font-weight: 900;
          font-size: 28px;
          color: #5eb049;
          line-height: 28px;
          -webkit-text-stroke: 1.5px #000000;
          margin-bottom: 25px;
        }
      }
    }
    .rightBox{
      position: relative;
      left: 20px;
      display: flex;
      align-items: center;
      .gif{
        margin-top: 120px;
        height: 529px;
      }
    }
  }
  @media (max-width: 768px) {
    padding-top: 0;
    display: block;
    height: fit-content;
    background-attachment: fixed;
    background-size: cover;
    .content{
      margin-top: 80px;
      display: block;
      padding: 30px 12px;
      align-items: center;
      .swapBox{
        min-height: fit-content;
        width: 100%;
        padding: 30px 25px 30px;
        /* background: url('${mobileSwapBodyBg}')top center/100% 100%; */
        &::after{
          display: none;
      }
      .decoratebox{
        left: -10px;
        top: -20px;
      }
        .box{
          padding: 23px 198;
        }
      }
      .center{
        margin-left: 0;
        .center-title{
          display: none;
        }
        .big-title{
          display: none;
        }
        .contentBox1{
          background: none;
          width: 100%;
          background: #335DB2;
          height:fit-content;
          border-radius: 16px 16px 16px 16px;
          border: 2px solid #000000;
          padding: 30px 18px 20px;
          .item{
            span{
              font-size: 13px;
              line-height: 14px;
              font-weight: 600;

            }
          }

        }
        .contentBox2{
          width: 100%;
          font-weight: 600;
          line-height: 16px;
          font-size: 13px;
          padding: 24px;
          border-radius: 16px 16px 16px 16px;
          margin-left: 0;
          margin-top: 0 !important;
        }
        .contentBox3{
          width: 100%;
          margin-left: 0;
          padding:24px 14px;
          line-height: 13px;
          height: fit-content;
          font-weight: 600;
          font-size: 13px;
          .content-title{
            font-weight: 900;
            font-size: 20px;
            margin-bottom: 12px;
          }

        }
      }
     
      .mobileSet{
        display: flex;
        flex-direction: column-reverse;
      }
      .moboileArrorw{
        width: 100%;
        height: fit-content;
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
      }
      .rightBox{
        margin-top: 25px;
        .gif{
          margin-top: 0;
        display: block;
        margin-left: auto;
        width: 228px;
        height: auto;
      }
        }
     
    }
  }
  @media (min-width: 768px) and (max-width: 1700px) {
    .content{
    padding: 0 20px;
    flex-wrap: nowrap;
    .swapBox{
      width: fit-content;
      min-width: 450px;
      padding: 20px;
      .decoratebox{
        left: -3px;
        top: -12px;
      }
      }
    .center{
      margin-left: 40px;
      .big-title{
        font-size: 50px;
      }
      .center-title{
        font-size: 36px;
      }
      .contentBox1{
        width: 450px;
        padding-right: 27px;
      }
      .contentBox2{
        max-width: 420px;
        margin-left: 0;
      }
      .contentBox3{
        max-width: 420px;
        margin-left: 0;
        .content-title{
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
    .rightBox{
      left: 0;
      .gif{
        height: 350px;
      }
    }
  }
  }
`

const Border = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  width: 100%;
  margin-top: 70px;
  height: 52px;
  background: #d84545;
  border-radius: 0px 0px 0px 0px;
  border: 6px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 0;
  border-right: 0;
  font-family: 'Inter var', sans-serif;
  @media (max-width: 768px) {
    display: none;
  }
  /* @media  (max-width: 768px an) {
    
  } */
`

const ArrowsLottieAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowsJson
  }

  return <Lottie options={defaultOptions} width={isMobile ? 30 : 65} style={{ margin: 0, marginLeft: '-60px' }} />
}

const BubbleJsonLottieAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bubbleJson
  }

  return (
    <Lottie
      options={defaultOptions}
      width={isMobile ? 68 : 119}
      className="test"
      style={{
        position: 'absolute',
        top: isMobile ? '-40%' : '23%',
        left: isMobile ? '30%' : '-60px',
        transform: isMobile ? 'rotate(80deg)' : ''
      }}
    />
  )
}

export default function HomeWrapBody({ children }) {
  return (
    <>
      {/* <Border className="border">🎉 Welcome to Pepeswap!</Border> */}

      <HomeWrap>
        <div className="content">
          <div className="swapBox">
            <div className="decoratebox">
              <div className="decorate1"></div>
              <div className="decorate2"></div>
              <div className="decorate3"></div>
            </div>
            <div className="box">{children && children}</div>
          </div>
          {isMobile && (
            <div className="moboileArrorw">
              <ArrowsLottieAnimation />
            </div>
          )}

          <div className="center">
            <div className="big-title">EMOJI BLOCKCHAIN!</div>
            <div className="center-title">Swap anytime anywhere</div>
            <div className="mobileSet">
              <div className="contentBox1">
                <div className="item">
                  <div className="item-title">Pepeswap</div>
                  <span>
                    The future of meme coins. A Layer 1 blockchain built for Speed, Security, Low Fees–and of
                    course–Memes.
                  </span>
                </div>
                <div className="item">
                  <div className="item-title">Pepe</div>
                  <span>
                    TOK powers the entire ecosystem. You’re early enough to witness a new golden age of Pepe Coins. With
                    Pepe in his rightful place as King, and the Pepeswap – his Kingdom.
                  </span>
                </div>
              </div>
              <div className="flex">
                {!isMobile && <ArrowsLottieAnimation />}
                <div className="contentBox2">
                  The all new pepe coin launch pad that lets you launch your own pepe coins in just a few clicks–right
                  on Pepeswap! Don’t let your pepes be dreams!
                </div>
              </div>
            </div>

            <div className="contentBox3">
              <div className="content-title">Pepe begin to understand...</div>
              Gateway to Pepe’s decentralized world. Explore trading, bridging, staking, and more with ease. Join the
              community to stay up to date on all new features.
            </div>
          </div>

          <div className="rightBox">
            <BubbleJsonLottieAnimation />
            <img className="gif" src={brainChair} />
          </div>
        </div>
      </HomeWrap>
    </>
  )
}
