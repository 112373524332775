// @ts-nocheck

import { ChainId } from '@uniswap/sdk'
import React, { useState, useRef, useEffect } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'
import mobileLogo from '../../assets/images/mobile-logo.png'

// import Logo from '../../assets/svg/logo.svg'
import Logo from '../../assets/images/logo.png'
import Tok from '../../assets/images/tok.png'
import tokLogo from '../../assets/images/logo.png'
import LogoDark from '../../assets/svg/logo_white.svg'
import Wordmark from '../../assets/svg/wordmark.svg'
import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import Modal from '../Modal'
import menuIcon from '../../assets/images/menu.png'
import closeMenu from '../../assets/images/closeMenu.png'

import { YellowCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column; */
  width: 100%;
  top: 0;
  /* position: sticky; */
  z-index: 2;
  /* background:red; */
  background: #c0d2c3;
  height: 70px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 5 0;
    width: calc(100%);
    top:36px;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    // margin-top: 0.5rem;
`};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  /* ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}; */
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  display: flex;
  vertical-align: middle;
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      vertical-align: middle;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  /* ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `}; */
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const MenuModal = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  left: 20%;
  z-index: 100;
  padding: 23px 10px;
  width: 127px;
  a {
    font-weight: 900;
    font-size: 16px;
    color: #191b1f;
    cursor: pointer;
    margin-bottom: 30px;
    text-decoration: none;
    &:hover {
      color: #3561ca;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .active {
    color: #3561ca;
  }
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.TOKLOCAL]: 'TokLocal',
  [ChainId.TOKTESTNET]: 'Tok',
  [ChainId.TOKNET]: 'Tok',
  [ChainId.SEPOLIA]: 'Sepolia'
}
const Router = styled.div`
  /* margin-left: 40px; */
  display: flex;
  align-items: center;
  gap: 70px;
  a {
    /* margin-right: 20px; */
    font-size: 22px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: #3561ca;
    }
  }

  .active {
    color: #3561ca;
  }
  @media (max-width: 768px) {
    display: none;
    margin-top: 2px;
    margin-left: 0px;
    a {
      font-size: 20px;
      margin-right: 10px;
    }
  }
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { pathname } = useLocation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  const [show, setShow] = useState(false)

  const menuRef = useRef<HTMLDivElement>()

  const history = useHistory()

  const handleClickOutside = event => {
    event.stopPropagation()
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // console.log(event)

      setShow(false)
    } else {
    }
  }
  function jumpSwap() {
    if (pathname === '/swap' || pathname === '/pool' || pathname === '/add/ETH') {
      return
    }
    history.push('/swap')
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      setShow(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <HeaderFrame>
      <RowBetween padding="1rem 1rem 1rem 1rem">
        <HeaderElement>
          <Title onClick={jumpSwap}>
            {/* <UniIcon>
              <img src={Logo} width={'23px'} alt="logo" />
            </UniIcon> */}
            <TitleText>
              {isMobile ? (
                <img width={32} src={mobileLogo} style={{ marginRight: '23px' }} alt="logo" />
              ) : (
                <img style={{ width: '255px' }} src={Tok} alt="logo" />
              )}
            </TitleText>
          </Title>
          {isMobile && (
            <div ref={menuRef} onClick={() => setShow(show ? false : true)}>
              {show ? <img src={closeMenu} width={19} /> : <img src={menuIcon} width={21} />}

              {show && (
                <MenuModal>
                  <NavLink to={'/swap'} className={pathname === '/swap' || pathname === '/pool' ? 'active' : ''}>
                    Swap
                  </NavLink>
                  <NavLink to={'/explore'} className={pathname === '/explore' ? 'active' : ''}>
                    Explore
                  </NavLink>
                  <a href="https://bridge.tokchain.org" target="_blank">
                    Bridge
                  </a>
                </MenuModal>
              )}
            </div>
          )}
        </HeaderElement>
        <Router>
          <NavLink to={'/swap'} className={pathname === '/swap' ? 'active' : ''}>
            Swap
          </NavLink>
          <NavLink to={'/explore'} className={pathname === '/explore' ? 'active' : ''}>
            Explore
          </NavLink>
          <a href="https://bridge.tokchain.org" target="_blank">
            Bridge
          </a>
        </Router>

        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {/* {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>} */}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {/* {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} ETH
                </BalanceText>
              ) : null} */}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {
              // <VersionSwitch />
            }
            <Settings />
            {/* <Menu /> */}
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
