import React, { Suspense,useEffect } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Explore from './Explore'
import Bridge from './Bridge'
import Tips from '../components/Tips'
import Test from './Test';
import Test2 from './Test/test2';


import EthrerumToken from '../pages/Explore/EthrerumToken'
import PoolDetail from '../pages/Explore/PoolDetail'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  display: block;
  position: fixed;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-top: 70px; */
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      // padding: 16px;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  /* margin-top: 5rem; */
`

// const Border = styled.div`
//   font-weight: 700;
//   font-size: 22px;
//   color: #ffffff;
//   width: 100%;
//   /* margin-top: 70px; */
//   height: 52px;
//   background: #d84545;
//   border-radius: 0px 0px 0px 0px;
//   border: 6px solid #000000;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-left: 0;
//   border-right: 0;
//   font-family: 'Inter var', sans-serif;
//   position: fixed;

//   @media (max-width: 768px) {
//     position: relative;
//     margin-top: 0;
//     height: 36px;
//     font-size: 12px;
//     border: none;
//     z-index: 2;

//     /* display: none; */
//   }
// `

export default function App() {
  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <HeaderWrapper>
            <Header />
            <Tips></Tips>
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />

            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route exact strict path="/explore" component={Explore} />
                <Route exact strict path="/explore/tokens/:token" component={EthrerumToken} />
                <Route exact strict path="/explore/pools/:token" component={PoolDetail} />
                <Route exact strict path="/bridge" component={Bridge} />
                <Route exact strict path="/test" component={Test} />
                <Route exact strict path="/test2" component={Test2} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
