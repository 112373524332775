// @ts-nocheck

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Line from './Line'
import Tokens from './Tokens'
import Pools from './Pools'
// import TokensMobile from './TokensMobile'
import PoolsList from './PoolsList'
import Transactions from './Transactions'
import TransactionsList from './TransactionsList'
import TransactionsPcList from './TransactionsPcList'

import { isMobile } from 'react-device-detect'
import TokensList from './TokensList'
import TokensPcList from './TokensPcList'
import PoolsPcList from './PoolsPcList'

const TabContent = styled.div<{ isVisible: boolean }>`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`
const TabContainer = styled.span`
  &.active {
    font-weight: bold;
    color: #007bff;
  }
`
const Tab = styled.div`
  margin-top: 40px;
  display: flex;
  span {
    color: rgb(125, 125, 125);
    cursor: pointer;
    font-size: 24px;
    margin-right: 20px;
    font-weight: 500;
    font-family: 'GrandstanderBlock';
    &:hover {
      opacity: 0.6;
    }
  }
  .active {
    color: #3561ca;
  }

  @media (max-width: 768px) {
    span {
      font-size: 20px;
      &:hover {
        opacity: 1;
      }
    }
  }
`
const Page = styled.div`
  width: 1200px;
  min-height: 100vh;
  margin-top: 140px;
  font-family: 'Inter var', sans-serif;
  @media (max-width: 768px) {
    margin-top: 100px;
    width: 100%;
    padding: 16px;
  }
`

export default function Explore() {
  const [current, setCurrent] = useState(0)
  const tab = [
    {
      id: 0,
      text: 'Tokens'
    },
    {
      id: 1,
      text: 'Pools'
    },
    {
      id: 2,
      text: 'Transactions'
    }
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Page>
      <Line></Line>
      <Tab>
        {tab.map(item => (
          <span key={item.id} className={`${item.id === current ? 'active' : ''}`} onClick={() => setCurrent(item.id)}>
            {item.text}
          </span>
        ))}
      </Tab>
      <div className="right"></div>

      {current === 0 && (isMobile ? <TokensList /> : <TokensPcList />)}
      {current === 1 && (isMobile ? <PoolsList /> : <PoolsPcList />)}
      {current === 2 && (isMobile ? <TransactionsList /> : <TransactionsPcList />)}

      {/* <TabContent isVisible={current === 0}>{isMobile ? <TokensMobile /> : <Tokens />}</TabContent>
      <TabContent isVisible={current === 1}>{isMobile ? <PoolsMobile /> : <Pools />}</TabContent>
      <TabContent isVisible={current === 2}>{isMobile ? <TransactionsMobile /> : <Transactions />}</TabContent> */}
    </Page>
  )
}
