// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Tokens as getToken } from '../../api/swap'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import formatLongNumber from '../../utils/formatLongNumber'
import axios from 'axios'
import nodata from '../../assets/images/knodata.png'

const Item = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background: rgba(255, 255, 255, 0.45);
  border: 1px solid #a7bfa7;

  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);
  border-radius: 15px;
  padding: 22px 52px 24px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  .name {
    display: flex;
    align-items: center;
    flex: 1;
    .currencyIcon {
      width: 20px;
      border-radius: 50%;
      overflow: hidden;
      img {
        border-radius: 50%;
        width: 100%;
      }
    }
    .currency {
      margin-left: 20px;
      span {
        display: block;
        &:first-child {
          font-weight: 400;
          font-size: 16px;
          color: #181818;
          opacity: 0.6;
        }
        &:last-child {
          font-weight: 700;
          font-size: 20px;
          color: #181818;
        }
      }
    }
  }
  .price {
    flex: 1;

    font-weight: 500;
    font-size: 20px;
    color: #181818;
  }
  .priceLimit {
    flex: 1.5;

    display: flex;
    align-items: center;
    /* gap: 30px; */
    div {
      width: 40%;
      font-weight: 500;
      font-size: 20px;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 5px;
      span {
      }
    }
  }
  .volume {
    flex: 1;

    font-weight: 400;
    font-size: 20px;
    color: #181818;
    span {
      &:first-child {
        opacity: 0.6;
      }
      &:last-child {
        font-weight: 500;
      }
    }
  }
  .chart {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 163px; */
    /* flex-grow: 1; */
    /* flex: 1; */
  }
`

export default function TokensMobile() {
  const history = useHistory()

  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getToken()
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    window.scrollTo(0, 0)
    fetchData()
  }, [])

  let [jsonData, setJsonData] = useState([])

  async function getJson() {
    let data = await axios.get('https://tokchain.org/static/tok1.json')
    setJsonData(data.data.tokens)
  }

  function getLogoURIByAddress(address) {
    const lowerCaseAddress = address.toLowerCase()
    // console.log(jsonData)

    const foundItem = jsonData?.find(item => item.address.toLowerCase() === lowerCaseAddress)

    return foundItem ? foundItem.logoURI : null
  }

  useEffect(() => {
    getJson()
  }, [])

  function jump(token) {
    history.push('/explore/tokens/' + token)
  }
  function addBeforeDash(str) {
    if (str > 0) {
      return '+' + str
    } else {
      return str.toString()
    }
  }

  return (
    <div className="list" style={{ marginTop: '16px' }}>
      {list.map((item, index) => {
        return (
          <Item key={index} onClick={() => jump(item.contract)}>
            <div className="name">
              <div className="currencyIcon">
                {getLogoURIByAddress(item.contract) && <img src={getLogoURIByAddress(item.contract)} />}
              </div>
              <div className="currency">
                <span>{item.name}</span>
                <span>{item.symbol}</span>
              </div>
            </div>
            <div className="price">${item?.price}</div>
            <div className="priceLimit">
              <div>
                <span>H</span>
                <span style={{ color: item['h1'] < 0 ? '#E65262' : '#12B298' }}>
                  {addBeforeDash(formatLongNumber(parseFloat(item['h1'] * 100), 2))}%
                </span>
              </div>
              <div>
                <span>D</span>
                <span style={{ color: item['d1'] < 0 ? '#E65262' : '#12B298' }}>
                  {addBeforeDash(formatLongNumber(parseFloat(item['d1'] * 100), 2))}%
                </span>
              </div>
            </div>
            <div className="volume">
              <span>Volume :</span>
              <span>${item.volume}</span>
            </div>
            <div className="chart">
              {item.price > 0 ? <img src={`/kl/${item.contract}_1d.svg`} /> : <img src={nodata} width={124} />}
            </div>

            {/*  <div className="item-left">
              <div className="left-box">
                <div className="price">${item?.price}</div>
                <div className="box">
                  <div className="data">
                    <div>
                      H{' '}
                      <span style={{ color: item['h1'] < 0 ? '#E65262' : '#12B298' }}>
                        {addBeforeDash(formatLongNumber(parseFloat(item['h1'] * 100), 2))}%
                      </span>
                      | D{' '}
                      <span style={{ color: item['d1'] < 0 ? '#E65262' : '#12B298' }}>
                        {addBeforeDash(formatLongNumber(parseFloat(item['d1'] * 100), 2))}%
                      </span>
                    </div>
                  </div>
                  <div className="volume">
                    Volume :<span>${item.volume}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-right">
              <div className="token">
                <span>{item.symbol}</span>
                {getLogoURIByAddress(item.contract) && <img src={getLogoURIByAddress(item.contract)} />}
              </div>
              <div className="token-name">{item.name}</div>
            </div> */}
          </Item>
        )
      })}
    </div>
  )
}
