// @ts-nocheck

import styled from 'styled-components'
import Tokens from './Tokens'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Swap from './Swap'
import LeftChart from './LeftChart'
import { Search, PairInfo } from '../../api/swap'
import { isMobile } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import Transactions from './PoolsTransactions'
import PoolsTransactionsList from './PoolsTransactionsList'
import PoolsChart from './PoolsChart'
import PoolsChart2 from '../../components/Charts/PoolsChart'
import subAddress from '../../utils/subAddress'
import Copy from '../../components/AccountDetails/Copy'

const Page = styled.div`
  font-family: 'Inter var', sans-serif;
  width: 1200px;
  margin: auto;
  margin-top: 150px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    &:first-child {
      width: 65%;
    }
    &:last-child {
      flex-shrink: 0;
      /* margin-left: 100px; */
      width: 350px;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    span {
      margin-right: 3px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .title {
    margin-top: 20px;
    font-size: 24px;
    color: #222;
    font-weight: 500;
    span {
      padding-left: 10px;
      color: #7d7d7d;
    }
  }
  .status {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: ; */
    div {
      span {
        display: block;
        &:first-child {
          font-size: 14px;
          color: rgb(125, 125, 125);
        }
        &:last-child {
          margin-top: 4px;
          font-size: 25px;
          color: rgb(34, 34, 34);
        }
      }
    }
  }
  .tab {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    span {
      font-size: 24px;
      color: #7d7d7d;
      cursor: pointer;
      font-weight: 600;
      &:hover {
        opacity: 0.7;
      }
    }
    .active {
      color: #222;
    }
  }
  .filtrate {
    margin-top: 10px;
    display: flex;
    align-items: center;
    border-radius: 28px;
    padding: 3px;
    background: rgba(255, 255, 255, 0.3);
    width: fit-content;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 59px;
      cursor: pointer;
      opacity: 0.8;
      font-weight: 600;
      height: 36px;
      border-radius: 24px;
      color: #999999;
    }
    .active {
      color: #ffffff;
      background: #5eb049;
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    padding: 16px;
    width: 100%;
    margin-top: 100px;
    .status {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
    & > div {
      width: 100% !important;
      &:last-child {
        display: nnone;
      }
    }
  }
`

export default function EthrerumToken() {
  const history = useHistory()
  const { token } = useParams<{ token?: any }>()
  const [tokenData, setTokenData] = useState({})

  function jump() {
    history.push('/explore')
  }

  async function searchData() {
    // let result = await Search({ wd: token })
    let { data: tokenData } = await PairInfo({ pair: token })
    console.log('🚀  searchData  tokenData:', tokenData.stats.tokenA.symbol)
    setTokenData(tokenData.stats)
  }
  let [chartData, setChartData] = useState(0)
  function getChartData(data: any) {
    setChartData(data)
  }
  const [type, setType] = useState('1d')

  const filtrate = [
    {
      id: 1,
      type: '1H',
      value: '1h'
    },
    {
      id: 2,
      type: '1D',
      value: '1d'
    },
    {
      id: 3,
      type: '1W',
      value: '1w'
    }
  ]

  useEffect(() => {
    searchData()
  }, [])
  return (
    <Page>
      <div>
        <div className="nav">
          <span onClick={jump}>Explore &gt;</span>
          <span onClick={jump}>Pools &gt;</span>
          <span>
            {' '}
            {tokenData?.tokenA?.name}/ {tokenData?.tokenB?.name}
          </span>
          <span style={{ fontWeight: 800, display: 'flex' }}>
            {subAddress(token, 4)}
            <Copy toCopy={token}></Copy>
          </span>
        </div>
        <div className="title">
          {tokenData?.tokenA?.symbol}/ {tokenData?.tokenB?.symbol}
          {/* <span>{tokenData?.symbol}</span> */}
        </div>
        {/* <div className="title">${chartData}</div> */}
        <PoolsChart2 address={token} type={type}/>

        <div className="filtrate">
          {filtrate.map(item => {
            return (
              <span key={item.id} className={type === item.value ? 'active' : ''} onClick={() => setType(item.value)}>
                {item.type}
              </span>
            )
          })}
        </div>
        {/* <PoolsChart token={token} onData={getChartData}></PoolsChart> */}
        {/* <LeftChart width={isMobile ? 320 : 780} height={isMobile ? 250 : 400}></LeftChart> */}

        <div className="status">
          <div>
            <span>TVL</span>
            <span>${tokenData?.tvl || '0.00'}</span>
          </div>

          <div>
            <span>24H volume</span>
            <span>${tokenData?.volume || '0.00'}</span>
          </div>
          {/* <div>
            <span>24H fees</span>
            <span></span>
          </div> */}
        </div>
        {/* <Transactions
          address={token}
          symbol0={tokenData?.tokenA?.symbol || ''}
          symbol1={tokenData?.tokenB?.symbol || ''}
        ></Transactions> */}
        <div className="tab">
          <span className="active">Transactions</span>
        </div>

        <PoolsTransactionsList
          address={token}
          symbol0={tokenData?.tokenA?.symbol || ''}
          symbol1={tokenData?.tokenB?.symbol || ''}
        />
      </div>
      <div>{!isMobile && <Swap></Swap>}</div>
    </Page>
  )
}
