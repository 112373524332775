// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import { Transactions as getTransactions, PoolTransactions } from '../../api/swap'
import styled from 'styled-components'
import subAddress from '../../utils/subAddress'
import multiply from '../../utils/multiply'
import formatLongNumber from '../../utils/formatLongNumber'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
let BROWSER = process.env.REACT_APP_BROWSER
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #a7bfa7;
  padding: 22px 32px;
  margin-bottom: 10px;

  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);

  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  .item-left {
    display: flex;
    flex-direction: column;
    .price {
      font-weight: 500;
      font-size: 20px;
      color: #181818;
      span {
        font-size: 16px;
        margin-left: 10px;
        opacity: 0.6;
      }
    }
    .data {
      display: flex;
      align-items: center;
      gap: 18px;
      margin-top: 16px;

      div {
        font-weight: 600;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        span {
          font-weight: 500;
          color: #707070;
        }
      }
      .pole {
      }
    }
    .volume {
      margin-top: 10px;
      font-weight: 400;
      font-size: 11px;
      color: #181818;
      span {
        font-weight: 600;
        font-size: 11px;
        color: #181818;
      }
    }
  }
  .item-center {
  }
  .item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .address {
      font-weight: 500;
      font-size: 16px;
      color: #181818;
      text-align: end;
    }
    .token {
      font-weight: 700;
      display: flex;
      align-items: center;

      font-size: 16px;
      color: rgba(24, 24, 24, 0.6);
      span {
        font-size: 20px;
        font-weight: 900;
        font-size: 20px;
        color: #e65262;
        margin-left: 10px;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 12px 14px;
    margin-bottom: 6px;
    .item-left {
      flex: 1;
      .price {
        font-weight: 700;
        font-size: 16px;
        span {
          font-size: 11px;
          margin-left: 6px;
        }
      }
      .data {
        margin-top: 10px;
        gap: 10px;

        div {
          font-size: 11px;
        }
      }
    }
    .item-right {
      .address {
        font-weight: 400;
        font-size: 11px;
      }
      .token {
        justify-content: flex-end;
        font-weight: 700;
        font-size: 11px;
        span {
          margin-right: 3px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
`

export default function Transactions({ address, symbol0 = '', symbol1 = '' }) {
  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await PoolTransactions({ Pair: address })
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [address])

  const headRef = useRef(null)
  const bodyRef = useRef(null)

  // 同步滚动
  const handleScroll = (source, target) => {
    target.current.scrollLeft = source.current.scrollLeft
  }

  function isMilliseconds(timestamp: any) {
    if (typeof timestamp !== 'string' && typeof timestamp !== 'number') {
      throw new TypeError('Parameter must be a string or a number')
    }
    return timestamp.toString().length === 13
  }

  const handleTimeAgo = (time1: any, time2 = moment(), flag: any) => {
    time1 = isMilliseconds(time1) ? time1 : time1 * 1000
    time2 = isMilliseconds(time2.valueOf()) ? time2.valueOf() : time2.valueOf() * 1000

    const duration = moment.duration(moment(time2).diff(moment(time1)))

    const totalDays = duration.asDays() 

    const timeUnits = [
      { unit: 'day', value: Math.floor(totalDays) }, 
      { unit: 'hr', value: duration.hours() },
      { unit: 'min', value: duration.minutes() },
      { unit: 'sec', value: duration.seconds() }
    ]

    for (const { unit, value } of timeUnits) {
      if (value > 0) {
        return `${value} ${unit}${value > 1 ? 's' : ''} ${flag ? '' : 'ago'}`
      }
    }

    return '1 sec'
  }

  const history = useHistory()
  function jump(token) {
    history.push('/explore/tokens/' + token)
  }

  function jumpBrowser(address) {
    window.open(BROWSER + '/tx/' + address)
  }

  function jumpAddressBrowser(address) {
    window.open(BROWSER + '/address/' + address)
  }

  return (
    <div className="list" style={{ marginTop: '16px' }}>
      {list.map((item, index) => {
        return (
          <Item
            key={index}
            onClick={() => {
              jumpBrowser(item.transactionHash)
            }}
          >
            <div className="item-left">
              <div className="price">
                ${isMobile ? formatLongNumber(item.usd, 2) : multiply(formatLongNumber(item.usd, 6))}
                <span>USD</span>
              </div>
              <div className="data">
                <div
                  onClick={e => {
                    e.stopPropagation()
                    jump(1)
                  }}
                >
                  <span>
                    {multiply(formatLongNumber(item?.amount0, 6))} {symbol0}
                  </span>
                  <span></span>
                </div>
                <div
                  onClick={e => {
                    e.stopPropagation()
                    jump(1)
                  }}
                >
                  <span>
                    {multiply(formatLongNumber(item?.amount1, 6))} {symbol1}
                  </span>
                </div>
              </div>
            </div>
            <div className="item-right">
              <div className="token">
                {handleTimeAgo(item.ts)}
                <span
                  style={{
                    fontWeight: '800',
                    color: item.type === 'buy' || item.type === 'add' ? '#12B298' : '#E65262'
                  }}
                >
                  {item.type} {item.type === 'buy' || item.type === 'sell' ? item.symbol0 : ''}
                </span>
              </div>
              <div className="address"> {subAddress(item?.from, 5)}</div>
            </div>
          </Item>
        )
      })}
    </div>
  )
}
