// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import formatLongNumber from '../../utils/formatLongNumber'
import axios from 'axios'
import { Pool } from '../../api/swap'

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.45);
  border: 1px solid #a7bfa7;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);
  border-radius: 15px;
  padding: 22px 52px 22px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  .item-left {
    display: flex;
    align-items: center;
    .key {
      font-weight: 400;
      font-size: 20px;
      color: #181818;
      margin-right: 38px;
    }
    .left-box {
      display: flex;
      flex-direction: column;
      .box {
        margin-top: 10px;
        display: flex;
        gap: 18px;
        .data {
          display: flex;
          align-items: center;
          div {
            font-weight: 400;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
          }
          span {
            margin-left: 4px;
            margin-right: 16px;
            font-weight: 500;
            font-size: 16px;
            color: #191b1f;
          }
          .pole {
          }
        }
      }

      .price {
        font-weight: 500;
        font-size: 20px;
        color: #181818;
        span {
          font-weight: 500;
          font-size: 16px;
          margin-right: 6px;
          opacity: 0.6;
        }
      }
    }
  }
  .item-right {
    .token {
      text-align: end;
      span {
        font-weight: 400;
        font-size: 11px;
        color: #181818;
      }
      img {
        width: 16px;
      }
    }
    .token-name {
      margin-top: 8px;
      font-weight: 700;
      font-size: 16px;
      color: #181818;
    }

    .boxImg {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 2px;
      .placeholder {
        width: 16px;
        border-radius: 50%;
        overflow: hidden;
      }
      img {
        width: 21px;
        border-radius: 50%;
        overflow: hidden;
        &:last-child {
          position: relative;
          z-index: 1;
        }
        &:first-child {
          position: relative;
          left: 5px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 12px 14px;
    margin-bottom: 6px;
    border-radius: 12px;
    align-items: flex-start;
    height: 96px;
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .key {
        display: none;
      }
      .left-box {
        .box {
          margin-top: 0;
          display: block;
          .data {
            margin-top: 10px;
            div {
              font-size: 12px;
            }
            span {
              font-weight: 500;
              font-size: 14px;
              color: #191b1f;
            }
          }
          .volume {
            margin-top: 10px;
            div {
              font-size: 13px;
            }
            span {
              font-weight: 500;
              font-size: 13px;
            }
          }
        }
        .price {
          font-weight: 700;
          font-size: 16px;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .item-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .volume {
        display: flex;
        align-items: center;
        /* margin-top: 10px; */
        font-weight: 400;
        color: #181818;
        font-size: 16px;
        div {
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
        }
        span {
          margin-left: 4px;
          margin-right: 6px;
          font-weight: 500;
          font-size: 14px;
          color: #191b1f;
        }
      }
      .token-name {
        font-weight: 700;
        font-size: 14px;
        color: #181818;
        margin-top: 6px;
        text-align: end;
      }
      .token-info {
        /* margin-top: 18px; */
      }
      .boxImg {
        img {
          width: 16px;
        }
      }
    }
  }
`

export default function PoolsMobile({ address }) {
  const history = useHistory()

  function jump(token) {
    history.push('/explore/pools/' + token)
  }
  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Pool({ wd: address })
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
    getJson()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let [jsonData, setJsonData] = useState([])
  async function getJson() {
    let data = await axios.get('https://tokchain.org/static/tok1.json')
    setJsonData(data.data.tokens)
  }
  function getLogoURIByAddress(address) {
    const lowerCaseAddress = address.toLowerCase()
    const foundItem = jsonData?.find(item => item.address.toLowerCase() === lowerCaseAddress)
    return foundItem ? foundItem.logoURI : null
  }

  return (
    <div className="list" style={{ marginTop: '16px' }}>
      {list.map((item, index) => {
        return (
          <Item key={index} onClick={() => jump(item.pair)}>
            <div className="item-left">
              <div className="key">{index + 1 <= 9 ? '0' + (index + 1) : index + 1}</div>
              <div className="left-box">
                <div className="price">
                  <span>TVL:</span>${item.tvl}
                </div>
                <div className="box">
                  <div className="data">
                    <div>1D vol:</div>
                    <span>${item.dayVol}</span>
                  </div>
                  <div className="data">
                    <div>30D vol :</div>
                    <span>${item.day30Vol}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-right">
              <div className="volume">
                <div>1D vol /TVL:</div>
                <span>{item.volTvlRatio24h}</span>
              </div>
              <div className="token-info">
                <div className="token">
                  {getLogoURIByAddress(item.token0) && getLogoURIByAddress(item.token1) ? (
                    <div className="boxImg">
                      <img src={getLogoURIByAddress(item.token0)} alt="" />
                      <img src={getLogoURIByAddress(item.token1)} alt="" />
                    </div>
                  ) : (
                    <div className="boxImg">
                      <div className="placeholder"></div>
                      <div className="placeholder"></div>
                    </div>
                  )}
                </div>
                <div className="token-name">
                  {item.symbol0}/{item.symbol1}
                </div>
              </div>
              {/* <div>1</div>
              <div>2</div> */}
            </div>
          </Item>
        )
      })}
    </div>
  )
}
