// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import { Transactions as getTransactions, PoolTransactions } from '../../api/swap'
import styled from 'styled-components'
import subAddress from '../../utils/subAddress'
import multiply from '../../utils/multiply'
import formatLongNumber from '../../utils/formatLongNumber'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
let BROWSER = process.env.REACT_APP_BROWSER

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #a7bfa7;
  padding: 22px 52px;
  margin-bottom: 10px;

  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);

  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.68);
  }
  .item-left {
    display: flex;
    flex-direction: column;
    .price {
      font-weight: 500;
      font-size: 20px;
      color: #181818;
      span {
        font-size: 16px;
        margin-left: 10px;
        opacity: 0.6;
      }
    }
    .data {
      display: flex;
      align-items: center;
      gap: 18px;
      margin-top: 16px;

      div {
        font-weight: 600;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        span {
          font-weight: 500;
          color: #707070;
        }
      }
      .pole {
      }
    }
    .volume {
      margin-top: 10px;
      font-weight: 400;
      font-size: 11px;
      color: #181818;
      span {
        font-weight: 600;
        font-size: 11px;
        color: #181818;
      }
    }
  }
  .item-center {
  }
  .item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .address {
      font-weight: 500;
      font-size: 16px;
      color: #181818;
      text-align: end;
    }
    .token {
      font-weight: 700;
      font-size: 20px;
      color: #181818;
      display: flex;

      opacity: 0.8;
      align-items: center;
      span {
        margin-right: 10px;
        font-weight: 400;
      }
      .link {
        margin: 0 5px;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 12px 14px;
    margin-bottom: 6px;
    align-items: center;
    .item-left {
      flex: 1.3;
      .price {
        font-weight: 700;
        font-size: 16px;
        span {
          font-size: 12px;
          margin-left: 6px;
        }
      }
      .data {
        margin-top: 10px;
        gap: 10px;
        font-size: 12px;
        div {
          font-size: 14px;
          &:first-child {
            width: 60%;
          }
          &:last-child {
            width: 40%;
          }
        }
        span {
          margin-bottom: 6px;
          display: block;
        }
      }
    }
    .item-right {
      /* align-items: center; */
      .address {
        margin-top: 14px;
        font-weight: 400;
        font-size: 14px;
        text-align: end;
      }
      .token {
        font-weight: 700;
        font-size: 11px;
        opacity: 1;
        span {
          margin-right: 3px;
          font-size: 14px;
          font-weight: 400;
          opacity: 0.6;
        }
      }
    }
  }
`

export default function TransactionsMobile({ address, type = 'token' }) {
  const history = useHistory()

  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (type === 'token') {
          const { data } = await getTransactions({ Token: address ? address : '' })
          setList(data)
        } else {
          let { data } = await PoolTransactions({ Pair: address })
          setList(data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [address])

  function isMilliseconds(timestamp: any) {
    if (typeof timestamp !== 'string' && typeof timestamp !== 'number') {
      throw new TypeError('Parameter must be a string or a number')
    }
    return timestamp.toString().length === 13
  }

  const handleTimeAgo = (time1: any, time2 = moment(), flag: any) => {
    // Ensure time1 and time2 are in milliseconds
    time1 = isMilliseconds(time1) ? time1 : time1 * 1000
    time2 = isMilliseconds(time2.valueOf()) ? time2.valueOf() : time2.valueOf() * 1000

    // Calculate the duration between time1 and time2
    const duration = moment.duration(moment(time2).diff(moment(time1)))

    // Get total days
    const totalDays = duration.asDays() // Get the total difference in days

    // Define time units
    const timeUnits = [
      { unit: 'day', value: Math.floor(totalDays) }, // Use integer number of days
      { unit: 'hr', value: duration.hours() },
      { unit: 'min', value: duration.minutes() },
      { unit: 'sec', value: duration.seconds() }
    ]

    // Iterate through the units to get the most significant one
    for (const { unit, value } of timeUnits) {
      if (value > 0) {
        return `${value} ${unit}${value > 1 ? 's' : ''} ${flag ? '' : 'ago'}`
      }
    }

    return '1 sec' // Fallback for very short time intervals
  }

  function jump(token) {
    history.push('/explore/tokens/' + token)
  }

  function jumpBrowser(address) {
    window.open(BROWSER + '/tx/' + address)
  }

  function jumpAddressBrowser(address) {
    window.open(BROWSER + '/address/' + address)
  }

  return (
    <div className="list" style={{ marginTop: '16px' }}>
      {list.map((item, index) => {
        return (
          <Item
            key={index}
            onClick={() => {
              jumpBrowser(item.transactionHash)
            }}
          >
            <div className="item-left">
              <div className="price">
                ${item?.usd > 0 && item?.usd < 0.01 ? '<0.01' : multiply(formatLongNumber(item?.usd, 2))}
                <span>{handleTimeAgo(item.ts)}</span>
              </div>
              <div className="data">
                <div
                  onClick={e => {
                    e.stopPropagation()
                    jump(item.token0)
                  }}
                >
                  <span> {formatLongNumber(item.symbol0)}</span>{' '}
                  {item?.amount0 > 0 && item?.amount0 < 0.01 ? '<0.01' : multiply(formatLongNumber(item?.amount0, 6))}
                </div>
                <div
                  onClick={e => {
                    e.stopPropagation()
                    jump(item.token1)
                  }}
                >
                  <span>{formatLongNumber(item.symbol1)}</span>{' '}
                  {item?.amount1 > 0 && item?.amount1 < 0.01 ? '<0.01' : multiply(formatLongNumber(item?.amount1, 2))}
                </div>
              </div>
            </div>
            <div className="item-right">
              <div className="token">
                <span>{item.type}</span>
                <div
                  className="link"
                  onClick={e => {
                    e.stopPropagation()
                    jump(item.token1)
                  }}
                >
                  {item.symbol0}
                </div>
                <span style={{ marginRight: 0 }}>
                  {item.type === 'add' || item.type === 'remove' ? 'and ' : 'for '}
                </span>
                <div
                  className="link"
                  style={{ marginRight: 0 }}
                  onClick={e => {
                    e.stopPropagation()
                    jumpAddressBrowser(item?.from)
                  }}
                >
                  {item.symbol1}
                </div>
              </div>
              <div className="address"> {subAddress(item?.from, 5)}</div>
            </div>
          </Item>
        )
      })}
    </div>
  )
}
