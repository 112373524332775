// @ts-nocheck

import { createChart } from 'lightweight-charts'
import React, { useEffect, useRef, useState } from 'react'
import { VolumeK } from '../../api/swap'

const ChartComponent = () => {
  const chartContainerRef = useRef(null)
  const [volumeKData, setVolumeKData] = useState([])

  useEffect(() => {
    if (!volumeKData.length) return

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight, // 明确设置高度
      autoSize: true,
      layout: {
        background: {
          type: 'solid',
          color: 'transparent' // 背景色
        },
        textColor: '#333',
        attributionLogo: false
      },
      handleScroll: false, // 禁止左右滑动
      handleScale: false, // 禁止缩放
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      },
      timeScale: {
        barSpacing: 0
      },
      rightPriceScale: {
        visible: false // 隐藏右侧价格轴
      },
      leftPriceScale: {
        visible: false // 隐藏左侧价格轴
      }
    })

    // 柱状图系列（显示交易量）
    const barSeries = chart.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume'
      },
      priceScaleId: ''
    })

    barSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0
      }
    })
    // 设置柱状图数据
    barSeries.setData(volumeKData)

    const firstDataPoint = volumeKData[0].time
    const lastDataPoint = volumeKData[volumeKData.length - 1].time
    chart.timeScale().setVisibleRange({
      from: firstDataPoint,
      to: lastDataPoint
    })

    chart.applyOptions({
      width: chartContainerRef.current.clientWidth,
      autoScale: false,
      scaleMargins: {
        top: 0.9,
        bottom: 0
      }
    })

    // 添加图例
    const legend = document.createElement('div')
    legend.style = `
      position: absolute;
      left: 12px;
      top: 12px;
      z-index: 1;
      font-size: 16px;
      font-family: 'Inter';
      line-height: 18px;
      color:#000
    `
    chartContainerRef.current.appendChild(legend)
    const firstRow = document.createElement('div')
    firstRow.innerHTML = `<div style="color:#7d7d7d">$TOK Volume</div> <div class="price-highlight">$${volumeKData[
      volumeKData.length - 1
    ]?.value || '0.00'}</div>`
    legend.appendChild(firstRow)

    // 添加工具提示
    const tooltip = document.createElement('div')
    tooltip.style = `
      position: absolute;
      display: none;
      background: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      font-size: 15px;
      color: black;
      pointer-events: none;
      z-index: 10;
      font-family: 'Inter';
    `
    chartContainerRef.current.appendChild(tooltip)

    chart.subscribeCrosshairMove(param => {
      let priceFormatted = volumeKData[volumeKData.length - 1].value
      if (param.time) {
        const dataPoint = param.seriesData.get(barSeries)
        const price = dataPoint?.value ?? dataPoint?.close
        priceFormatted = price ? price.toFixed(2) : '0'

        const { point } = param
        if (point) {
          tooltip.style.left = `${point.x + 10}px` // 添加偏移量
          tooltip.style.top = `${point.y + 10}px` // 添加偏移量
          tooltip.style.display = 'block'
          tooltip.innerHTML = `
            Value: ${priceFormatted}<br />
          `
        }
      } else {
        tooltip.style.display = 'none'
      }
      firstRow.innerHTML = `<div style="color:#7d7d7d">$TOK Volume</div> <div class="price-highlight">$${priceFormatted}</div>`
    })

    chart.timeScale().fitContent()

    return () => {
      chart.remove()
    }
  }, [volumeKData])

  useEffect(() => {
    async function getKlineData() {
      try {
        let { data } = await VolumeK()
        data.forEach(i => {
          i.time = i.timestamp
          i.value = i.volumeU
        })
        setVolumeKData(() => {
          return data
        })
      } catch (error) {
        console.error('获取 Kline 数据失败:', error)
      }
    }

    getKlineData()
  }, [])

  return (
    <div
      ref={chartContainerRef}
      style={{
        position: 'relative', // 确保容器相对定位
        width: '600px',
        height: '400px',
        marginTop: '200px'
      }}
    />
  )
}

export default function Test() {
  return <ChartComponent />
}
