import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
const Tips = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  width: 100%;
  /* margin-top: 70px; */
  height: 52px;
  background: #d84545;
  border-radius: 0px 0px 0px 0px;
  border: 6px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 0;
  border-right: 0;
  font-family: 'Inter var', sans-serif;

  
  @media (max-width: 768px) {
    position: relative;
    margin-top: 0;
    height: 36px;
    font-size: 12px;
    border: none;
    z-index: 2;

    /* display: none; */
  }
`
export default function Index() {
  return <Tips>🎉 Welcome to Pepeswap!</Tips>
}
