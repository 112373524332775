// @ts-nocheck

import styled from 'styled-components'
import Tokens from './Tokens'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Swap from './Swap'
import LeftChart from './LeftChart'
import { Search, TokenKLine, TokenInfo } from '../../api/swap'
import { isMobile } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import TransactionsDetailList from './TransactionsDetailsList'

import PoolsDetailsList from './PoolsDetailsList'
import PoolsPcDetailsList from './PoolsPcDetailsList'

import DetailChart from './DetailChart'
import subAddress from '../../utils/subAddress'
import Copy from '../../components/AccountDetails/Copy'
import TokenChrat from '../../components/Charts/TokenChart'

const Page = styled.div`
  width: 1200px;
  margin: auto;
  margin-top: 150px;
  font-family: 'Inter var', sans-serif;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    &:first-child {
      width: 65%;
    }
    &:last-child {
      flex-shrink: 0;
      /* margin-left: 100px; */
      width: 350px;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
      margin-right: 3px;
    }
  }
  .title {
    margin-top: 20px;
    font-size: 24px;
    color: #222;
    font-weight: 500;
    span {
      padding-left: 10px;
      color: #7d7d7d;
    }
  }
  .status {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: ; */
    div {
      span {
        display: block;
        &:first-child {
          font-size: 14px;
          color: rgb(125, 125, 125);
        }
        &:last-child {
          margin-top: 4px;
          font-size: 25px;
          color: rgb(34, 34, 34);
        }
      }
    }
  }
  .tab {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    span {
      font-size: 24px;
      color: #7d7d7d;
      cursor: pointer;
      font-weight: 600;
      &:hover {
        opacity: 0.7;
      }
    }
    .active {
      color: #222;
    }
  }
  .filtrate {
    margin-top: 10px;
    display: flex;
    align-items: center;
    border-radius: 28px;
    padding: 3px;
    background: rgba(255, 255, 255, 0.3);
    width: fit-content;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 59px;
      cursor: pointer;
      opacity: 0.8;
      font-weight: 600;
      height: 36px;
      border-radius: 24px;
      color: #999999;
    }
    .active {
      color: #ffffff;
      background: #5eb049;
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    padding: 16px;
    width: 100%;
    margin-top: 110px;
    padding-top: 0;
    .status {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
    & > div {
      width: 100% !important;
      &:last-child {
        display: nnone;
      }
    }
  }
`

export default function EthrerumToken() {
  const history = useHistory()
  const { token } = useParams<{ token?: any }>()
  const [kData, setKdata] = useState([])
  const [tokenData, setTokenData] = useState({})

  const tab = [
    {
      id: 1,
      text: 'Transactions'
    },
    {
      id: 2,
      text: 'Pools'
    }
  ]

  let [current, setCurrent] = useState(1)
  function jump() {
    history.push('/explore')
  }

  async function searchData() {
    // let result = await Search({ wd: token })
    // let { data } = await TokenKLine({ token, t: '1d' })
    let { data: tokenData } = await TokenInfo({ token })
    console.log('🚀  searchData  tokenData:', tokenData)
    setTokenData(tokenData)
    // setKdata(data)
  }

  // useEffect(()=>{
  //   window.scrollTo(0, 0)

  // },[])
  useEffect(() => {
    window.scrollTo(0, 0)

    searchData()
  }, [])

  const [type, setType] = useState('1d')

  const filtrate = [
    {
      id: 1,
      type: '1H',
      value: '1h'
    },
    {
      id: 2,
      type: '1D',
      value: '1d'
    },
    {
      id: 3,
      type: '1W',
      value: '1w'
    }
  ]
  return (
    <Page>
      <div>
        <div className="nav">
          <span onClick={jump}>Explore &gt;</span>
          <span onClick={jump}>Tokens &gt;</span>
          <span>{tokenData?.name}</span>
          <span style={{ fontWeight: 800, display: 'flex' }}>
            {subAddress(token, 5)}
            <Copy toCopy={token}></Copy>
          </span>
        </div>
        <div className="title">
          {tokenData?.name}
          <span>{tokenData?.symbol}</span>
        </div>
        <TokenChrat address={token} type={type}></TokenChrat>
        <div className="filtrate">
          {filtrate.map(item => {
            return (
              <span key={item.id} className={type === item.value ? 'active' : ''} onClick={() => setType(item.value)}>
                {item.type}
              </span>
            )
          })}
        </div>

        <div className="status">
          <div>
            <span>TVL</span>
            <span>${tokenData?.stats?.tvl || '0.00'}</span>
          </div>
          {/* <div>
            <span>Market cap</span>
            <span>0.00</span>
          </div>
          <div>
            <span>FDV</span>
            <span>0.00</span>
          </div> */}
          <div>
            <span>1 day volume</span>
            <span>${tokenData?.stats?.volume || '0.00'}</span>
          </div>
        </div>
        <div className="tab">
          {tab.map(i => {
            return (
              <span
                key={i.id}
                className={current === i.id ? 'active' : ''}
                onClick={() => {
                  setCurrent(i.id)
                }}
              >
                {i.text}
              </span>
            )
          })}
        </div>

        {current === 1 && <TransactionsDetailList address={token} symbol={tokenData.symbol} />}
        {current === 2 && (isMobile ? <PoolsDetailsList address={token} /> : <PoolsPcDetailsList address={token} />)}
      </div>

      <div>{!isMobile && <Swap></Swap>}</div>
    </Page>
  )
}
