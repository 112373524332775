// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TvlK from '../../components/Charts/TvlK'
import VolumeK from '../../components/Charts/VolumeK'
import LeftChart from './LeftChart'
import RightChat from './RightChat'

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* background: #fff; */
  gap: 14px;
  .box {
    padding: 20px;
    padding-top: 10px;
    width: 50%;
    background: rgba(197, 197, 197, 0.18);
    border: 1px solid #000;
    backdrop-filter: blur(13px);
    border-radius: 20px;

    .title {
      color: #7d7d7d;
      display: none;
    }

    .data {
      margin-top: 20px;
      color: '#000';
      font-size: 30px;
      font-weight: 500;
      display: none;
    }

    .img {
      width: 100%;

      /* height: 400px; */
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .box {
      width: 48%;
      height: 110px;
      /* background: #f9f9f9; */
      border-radius: 10%;
      padding: 20px;
      /* display: none; */
      .title {
        font-size: 12px;
        display: block;
      }
      .data {
        display: block;
        font-size: 20px;
      }
    }
    .img {
      display: none;
    }
  }
`

export default function Line() {
  const [leftData, setData] = useState(0)
  const [rightData, setRightData] = useState(0)

  function getData(data: any) {
    // console.log(data);
    setData(data)
  }

  function getRightData(data: any) {
    // console.log(data);
    setRightData(data)
  }

  return (
    <Wrap>
      <div className="box">
        <div className="title">Tokswap TVL</div>
        <div className="data">${leftData}</div>
        <div className="img">
          {/* <LeftChart width={530} height={340}></LeftChart> */}
          <TvlK onData={getData}></TvlK>
        </div>
      </div>
      <div className="box">
        <div className="title">Tokswap volume</div>
        <div className="data">${rightData}</div>
        <div className="img">
          {/* <RightChat height={340} onData={getRightData}></RightChat> */}
          <VolumeK onData={getRightData} />
        </div>
        {/* <TvlK></TvlK> */}
      </div>
    </Wrap>
  )
}
