// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react'
import { createChart } from 'lightweight-charts'

import { TvlK } from '../../api/swap'

export default function LightweightChartWithLegend({ onData }) {
  const chartContainerRef = useRef(null)
  const [klineData, setKlineData] = useState([])

  useEffect(() => {
    if (!klineData.length) return
    onData && onData(klineData[klineData.length - 1]?.tvlU.toFixed(2))
    const chartOptions = {
      layout: {
        textColor: 'black',
        background: { type: 'solid', color: '#f9f9f9' }
      }
    }

    const chart = createChart(chartContainerRef.current, chartOptions)

    chart.applyOptions({
      handleScroll: false,
      handleScale: false,
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25
        }
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        }
      },
      grid: {
        vertLines: { visible: false },
        horzLines: { visible: false }
      },
      rightPriceScale: {
        visible: false
      },
      timeScale: {
        barSpacing: 5,
        borderVisible: false
      },
      layout: {
        background: {
          type: 'solid',
          color: 'transparent'
        },
        textColor: '#333',
        attributionLogo: false
      }
    })

    const areaSeries = chart.addAreaSeries({
      topColor: 'rgba(25, 222, 166, 1)',
      bottomColor: 'rgba(25, 222, 166, 0)',
      lineColor: 'rgba(23, 209, 157, 1)',
      lineWidth: 2,
      priceLineVisible: false,
      crossHairMarkerVisible: false,
      scaleMargins: {
        top: 0.1, // 顶部留白
        bottom: 0 // 禁止底部留白
      }
    })

    const data = klineData
    areaSeries.setData(data)

    const symbolName = 'Tokswap TVL'

    const legend = document.createElement('div')
    legend.style = `
      position: absolute;
      left: 0px;
      top: 12px;
      z-index: 1;
      font-size: 16px;
      font-family: 'Inter';
      line-height: 18px;
    `
    chartContainerRef.current.appendChild(legend)

    const firstRow = document.createElement('div')
    firstRow.innerHTML = `<div style="color:#7d7d7d">${symbolName}</div> <div class="price-highlight">$${data[
      data.length - 1
    ]?.value || '0.00'}</div>`
    legend.appendChild(firstRow)

    const tooltip = document.createElement('div')
    tooltip.style = `
      position: absolute;
      display: none;
      background: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      font-size: 15px;
      color: black;
      pointer-events: none;
      z-index: 10;
      font-family: 'Inter';
    `
    chartContainerRef.current.appendChild(tooltip)

    chart.subscribeCrosshairMove(param => {
      let priceFormatted = data[data.length - 1].value

      if (param.time) {
        const dataPoint = param.seriesData.get(areaSeries)
        const price = dataPoint?.value ?? dataPoint?.close
        priceFormatted = price ? price: '0'

        const { point } = param
        if (point) {
          tooltip.style.left = `${point.x}px`
          tooltip.style.top = `${point.y}px`
          tooltip.style.display = 'block'
          tooltip.innerHTML = `
            $${priceFormatted}<br />
          `
        }
      } else {
        tooltip.style.display = 'none'
      }
      firstRow.innerHTML = `<div style="color:#7d7d7d">${symbolName}</div> <div class="price-highlight">$${priceFormatted}</div>`
    })

    chart.timeScale().fitContent()

    return () => {
      chart.remove()
    }
  }, [klineData])

  useEffect(() => {
    async function getKlineData() {
      try {
        let { data } = await TvlK()
        data.forEach(i => {
          i.time = i.timestamp
          i.value = i.tvlU
        })
        setKlineData(() => {
          return data
        })
      } catch (error) {
        console.error('获取 Kline 数据失败:', error)
      }
    }

    getKlineData()
  }, [])

  return <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '450px' }} />
}

// CSS Styles
const styles = document.createElement('style')
styles.innerHTML = `
  .price-highlight {
    margin-top: 20px;
    color: '#000';
    font-size: 30px;
    font-weight: 500;
  }
`
document.head.appendChild(styles)
